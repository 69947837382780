import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as complaintApis from "../../utilities/apis/complaint"
import { getComplaintStatus } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"
import moment from "moment"

const Complaints = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const categoryRef = useRef(null)
  const statusRef = useRef(null)
  const mobileRef = useRef(null)
  const [complaints, setComplaints] = useState([])
  const [active, setActive] = useState(1)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({
    trash: false,
    offset: 0,
    limit: 10,
  })

  const handleSearch = () => {
    const where = {}

    if (categoryRef.current.value) where.category = categoryRef.current.value
    if (statusRef.current.value) where.status = statusRef.current.value
    if (mobileRef.current.value) where.mobile = mobileRef.current.value

    setFilters({ ...filters, offset: 0, ...where })
    setActive(1)
  }

  const handleReset = () => {
    setFilters({ limit: 10, offset: 0 })
    categoryRef.current.value = ''
    statusRef.current.value = ''
    mobileRef.current.value = ''
    setActive(1)
  }

  const handleTrash = () => {
    setFilters({ ...filters, trash: !filters.trash })
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to trash?")) return

      const response = await complaintApis.deleteComplaint(token, id)
      if (!response.status) throw new Error(response.message)

      setComplaints((previous) => {
        const complaints = previous.filter(x => x.id !== id)
        return [...complaints]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handleRestore = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to restore?")) return

      const response = await complaintApis.restoreComplaint(token, id)
      if (!response.status) throw new Error(response.message)

      getComplaints()
    } catch (error) {
      alert(error.message)
    }
  }

  const getComplaints = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await complaintApis.getComplaints(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setCount(response.count)
      setComplaints(response.complaints)
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    setFilters({ ...filters, offset })
    setActive(page)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getComplaints()
  }, [])

  useEffect(() => {
    getComplaints(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers">
        <div className="container-fluid">
          <div className="row align-items-center my-2">
            <div className="col-6">
              <h3 className="fw-bold mb-0">Complaints ({complaints.length})</h3>
            </div>
            <div className="d-flex col-6 gap-2">
              <button type="button" className={`btn btn-sm btn-${filters.trash ? 'danger' : 'outline-danger'}`} onClick={handleTrash}>Trash</button>
              <select ref={categoryRef} className="form-select">
                <option value="">Select Category</option>
                <option value="payment issue">Payment Issue</option>
                <option value="packing issue">Packing Issue</option>
                <option value="missing item">Missing Item</option>
                <option value="product quality">Product Quality</option>
                <option value="delivery issue">Delivery Issue</option>
                <option value="miscellaneous">Miscellaneous</option>
              </select>
              <select ref={statusRef} className="form-select">
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="assigned">Assigned</option>
                <option value="resolved">Resolved</option>
              </select>
              <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" required autoFocus />
              <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
              <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "9%" }} className="align-middle">Category</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle">Name</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle">Mobile</th>
              <th scope="col" style={{ width: "37%" }} className="align-middle">Message</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle">Rider</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Status</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle">Datetime</th>
              <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {complaints.map((row) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-capitalize">{row.category}</td>
                <td className="align-middle">{row.name || "N/A"}</td>
                <td className="align-middle">{row.customer.mobile}</td>
                <td className="align-middle">{row.message}</td>
                <td className="align-middle">{row.driver && row.driver.name}</td>
                <td className="align-middle text-center">{getComplaintStatus[row.status]}</td>
                <td className="align-middle">{moment(row.created_at).format('DD-MM-YYYY HH:mm')}</td>
                <td className="align-middle text-center">
                  {(permissions["READ_COMPLAINT"]) && !filters.trash && (<a className="me-2" href={`/dashboard/complaint?id=${row.id}`}><i className="far fa-clipboard"></i></a>)}
                  {(permissions["DELETE_COMPLAINT"]) && !filters.trash && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                  {(permissions["DELETE_COMPLAINT"]) && filters.trash && (<a className="text-success" onClick={() => handleRestore(row.id)}><i className="fas fa-trash-restore-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <Pagination
              currentPage={active}
              totalCount={count}
              pageSize={filters.limit}
              offset={filters.offset}
              siblingCount={3}
              onPageChange={handlePageChange}
            />
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default Complaints
