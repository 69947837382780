import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createComplaintComment = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/complaint_comment`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateComplaintComment = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/complaint_comment`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteComplaintComment = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/complaint_comment/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getComplaintComments = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/complaint_comments?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getComplaintComment = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/complaint_comment?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}