import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { debounce } from "lodash"
import * as customerApis from "../utilities/apis/customer"
// import * as orderHistoryApis from "../utilities/apis/order_history"
import * as complaintApis from "../utilities/apis/complaint"
import * as orderApis from "../utilities/apis/order"
import * as functions from "../utilities/enumerations/functions"
import { getComplaintStatus, getOrderStatus, isDeal } from "../utilities/enumerations/constants"
import Layout from "../components/Layout"

const Dashboard = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const mobileRef = useRef(null)
  const addressRef = useRef(null)
  const zoneRef = useRef(null)
  const [customers, setCustomers] = useState([])
  // const [orderHistories, setOrderHistories] = useState([])
  const [complaints, setComplaints] = useState([])
  const [orders, setOrders] = useState([])
  const [active, setActive] = useState(-1)

  // const getOrderHistories = async (params) => {
  //   try {
  //     const response = await orderHistoryApis.getOrderHistories(token, functions.paramsToQuery(params))
  //     if (!response.status) throw new Error(response.message)

  //     setOrderHistories(response.order_histories)
  //   } catch (error) {
  //     alert(error.message)
  //   }
  // }

  const getComplaints = async (params) => {
    try {
      const response = await complaintApis.getComplaints(token, functions.paramsToQuery(params))
      if (!response.status) throw new Error(response.message)

      setComplaints(response.complaints)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrders = async (params) => {
    try {
      params.limit = 8
      const response = await orderApis.getOrders(token, functions.paramsToQuery(params))
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleResolve = async (id) => {
    try {
      const response = await complaintApis.resolveComplaint(token, { id })
      if (!response.status) throw new Error(response.message)

      setComplaints(complaints.map((complaint) => ({ ...complaint, status: 'resolved' })))
    } catch (error) {
      alert(error.message)
    }
  }

  const handleSubmit = () => {
    const mobile = mobileRef.current.value
    const address = addressRef.current.value
    const zone = zoneRef.current.value

    let isValid = true

    if (!mobile) {
      mobileRef.current.classList.add("border-danger")
      isValid = false
    }
    if (!address) {
      addressRef.current.classList.add("border-danger")
      isValid = false
    }
    if (!isValid) return

    sessionStorage.setItem("customer", JSON.stringify({ mobile, address, zone }))
    navigate("/dashboard/cart")
  }

  const goDown = () => {
    setActive((previous) => {
      if (previous >= customers.length)
        previous = 0
      else
        previous += 1

      mobileRef.current.value = customers[previous]?.mobile || ""
      return previous
    })
  }

  const goUp = () => {
    setActive((previous) => {
      if (previous <= 0)
        previous = customers.length
      else
        previous -= 1

      mobileRef.current.value = customers[previous]?.mobile || ""
      return previous
    })
  }

  const handleChange = useCallback(debounce(async (e) => {
    try {
      const mobile = e.target.value
      if (mobile.length < 1) return

      const response = await customerApis.getCustomers(token, functions.paramsToQuery({ mobile }))
      if (!response.status) throw new Error(response.message)
      if (response.customers && response.customers.length === 0) throw new Error(response.message)

      setCustomers(response.customers)
    } catch (error) {
      addressRef.current.value = ""
      setOrders([])
      // getOrderHistories([])
      setCustomers([])
    }
  }, 800), [])

  const handleInputKeyup = (e) => {
    if (e.which === 40) goDown()
    if (e.which === 38) goUp()
    if (e.which === 13) handleSelect(e.target.value)
  }

  const handleSelect = (value) => {
    const customer = customers.find((customer) => customer.mobile === value)
    if (!customer) return

    mobileRef.current.value = customer.mobile
    addressRef.current.value = customer.address
    zoneRef.current.value = customer.zone.name
    setCustomers([])
    getOrders({ customer_id: customer.id })
    // getOrderHistories({ customer_id: customer.id })
    getComplaints({ customer_id: customer.id })
  }

  useEffect(() => {
    if (!user) navigate("/")
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order">
        <section className="flex-grow-1 border-end d-flex flex-column overflow-y-auto">
          <div className="bg-light border-bottom p-3">
            <h3 className="fw-bold m-0">Find or Create Customer</h3>
          </div>
          <div className="p-3">
            <input ref={zoneRef} type="hidden" id="zone" />

            <div className={`form-floating mb-3 ${customers.length ? "show" : "hide"}`}>
              <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" onChange={handleChange} onKeyUp={handleInputKeyup} required autoFocus />
              <label htmlFor="mobile">Mobile #</label>

              <ul className={`autocomplete ${customers.length ? "show" : "hide"}`}>
                {customers.map((customer, index) => {
                  return (
                    <li key={customer.id.toString()} className={active === index ? "active" : ""} onClick={() => handleSelect(customer.mobile)}>{customer.mobile}</li>
                  )
                })}
              </ul>
            </div>
            <div className="form-floating mb-3">
              <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address" required />
              <label htmlFor="address">Address</label>
            </div>
            <button type="button" className="btn btn-primary w-100 py-3" onClick={handleSubmit}>Continue</button>
          </div>
          {/* <div className="bg-light border-top border-bottom p-3">
            <h3 className="fw-bold m-0">Order History</h3>
          </div>
          <div className="overflow-y-auto">
            <table className="table table-sm table-bordered shadow-none text-dark mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "50%" }} className="align-middle">Product</th>
                  <th scope="col" style={{ width: "25%" }} className="align-middle">Quantity</th>
                  <th scope="col" style={{ width: "25%" }} className="align-middle">Last Order</th>
                </tr>
              </thead>
              <tbody>
                {orderHistories.map((row) => {
                  return (
                    <tr key={row.id.toString()}>
                      <td className="align-middle">{row.product.title}</td>
                      <td className="align-middle">{row.quantity}</td>
                      <td className="align-middle">{moment(row.last_order).format("DD-MM-YYYY")}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div> */}
          <div className="bg-light border-top border-bottom p-3">
            <h3 className="fw-bold m-0">Recent Complaint</h3>
          </div>
          <div className="overflow-y-auto">
            <table className="table table-sm table-bordered table-hover shadow-none">
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "15%" }} className="align-middle">Name</th>
                  <th scope="col" style={{ width: "15%" }} className="align-middle">Mobile</th>
                  <th scope="col" style={{ width: "40%" }} className="align-middle">Message</th>
                  <th scope="col" style={{ width: "15%" }} className="align-middle text-center">Status</th>
                  <th scope="col" style={{ width: "15%" }} className="align-middle">Datetime</th>
                  <th scope="col" style={{ width: "12%" }} className="align-middle text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {complaints.map((row) => (
                  <tr key={row.id.toString()}>
                    <td className="align-middle">{row.name || "N/A"}</td>
                    <td className="align-middle">{row.customer.mobile}</td>
                    <td className="align-middle">{row.message}</td>
                    <td className="align-middle text-center">{getComplaintStatus[row.status]}</td>
                    <td className="align-middle">{moment(row.created_at).format('DD-MM-YYYY HH:mm')}</td>
                    <td className="align-middle text-center">
                      {(permissions["READ_COMPLAINT"]) && (<a className="me-2" href={`/dashboard/complaint?id=${row.id}`}><i className="far fa-clipboard"></i></a>)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        <aside className="flex-grow-1 border-start d-flex flex-column">
          <div className="bg-light border-bottom p-3">
            <h3 className="fw-bold m-0">Orders</h3>
          </div>
          <ul className="flex-grow-1 overflow-y-auto list-group list-group-flush shadow-none">
            {orders.map((order) => {
              return (
                <li key={order.id.toString()} className="list-group-item d-flex justify-content-between align-items-center">
                  <div>
                    <p className="m-0"><span className="fw-bold">ORDER #:</span> P{order.id}</p>
                    <p className="m-0">{moment(order.order_datetime).format("DD-MM-YY HH:mm")}</p>
                    <p>{order.customer.mobile}</p>

                    {order.driver && <>
                      <p className="fw-bold m-0">RIDER</p>
                      <p>{order.driver.name}</p>
                    </>}
                  </div>
                  <div className="text-end">
                    <p className="m-0">{getOrderStatus[order.order_status]}</p>
                    {order.order_lineitems.map((item, index) => {
                      return (
                        <p key={index.toString()} className="m-0">{
                          isDeal(item.type)
                            ? `${item.title} x${item.quantity} = ${(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}`
                            : `${item.title} x${item.quantity} = ${(item.quantity * item.price).toFixed(2)}`
                        }</p>
                      )
                    })}
                    <p className="mt-3 m-0"><span className="fw-bold">DISCOUNT:</span> Rs. {order.discount.toFixed(2)}</p>
                    <p className="m-0"><span className="fw-bold">TOTAL:</span> Rs. {(order.total - order.discount).toFixed(2)}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        </aside>
      </main>
    </Layout>
  )
}

export default Dashboard
