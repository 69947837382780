import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as driverApis from "../../utilities/apis/driver"
import * as complaintApis from "../../utilities/apis/complaint"
import * as complaintCommentApis from "../../utilities/apis/complaintComment"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { getComplaintStatus } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import moment from "moment"

const User = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const statusRef = useRef(null)
  const driverRef = useRef(null)
  const messageRef = useRef(null)
  const [edit, setEdit] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [complaint, setComplaint] = useState({})
  const [complaintComments, setComplaintComments] = useState([])
  const params = new URLSearchParams(window.location.search)

  const handleAssign = async (e) => {
    if (!driverRef.current.value) return alert('Select a Rider')

    try {
      const payload = []
      payload.push({
        complaint_id: params.get("id"),
        driver_id: parseInt(driverRef.current.value)
      })
      const response = await dispatcherServices.complaintAssigned(token, payload)
      if (!response.status) throw new Error(response.message)

      getComplaint()
    } catch (error) {
      alert(error.message)
    }
  }

  const handleResolve = async () => {
    try {
      const response = await complaintApis.resolveComplaint(token, { id: complaint.id })
      if (!response.status) throw new Error(response.message)

      getComplaint()
    } catch (error) {
      alert(error.message)
    }
  }

  const getDrivers = async () => {
    try {
      const response = await driverApis.getDrivers(token, {})
      if (!response.status) throw new Error(response.message)

      const activeDriver = response.drivers.filter(driver => driver.active === true)
      setDrivers(activeDriver)
    } catch (error) {
      alert(error.message)
    }
  }

  const getComplaint = async () => {
    try {
      const response = await complaintApis.getComplaint(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setComplaint(response.complaint)
      driverRef.current.value = response.complaint.driver_id
    } catch (error) {
      alert(error.message)
    }
  }

  const getComplaintComments = async () => {
    try {
      const query = new URLSearchParams({ complaint_id: params.get("id") })
      const response = await complaintCommentApis.getComplaintComments(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setComplaintComments(response.complaint_comments)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleStatusChange = async (e) => {
    try {
      const payload = {
        id: params.get("id"),
        complaint_id: complaint.id,
        status: e.target.value
      }
      const response = await complaintApis.updateComplaint(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Complaint status changed successfully")
      statusRef.current.value = ""
      setEdit(false)
      getComplaint()
    } catch (error) {
      alert("Error creating order: " + error.message)
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const payload = {
        message: messageRef.current.value,
        complaint_id: complaint.id
      }
      const response = await complaintCommentApis.createComplaintComment(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Complaint Comment submitted successfully")
      messageRef.current.value = ""
      getComplaintComments()
    } catch (error) {
      alert("Error creating order: " + error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id")) {
      getDrivers()
      getComplaint()
      getComplaintComments()
    }
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-header bg-white border-bottom-0">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="fw-bold m-0">Complaint #: {complaint.id}</h3>
                    </div>
                    <div className="col-md-4 px-1">
                      <select ref={driverRef} className="form-select w-100">
                        <option key="0" value="">Select Rider</option>
                        {drivers.map((driver) => (
                          <option key={driver.id.toString()} value={driver.id}>{driver.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 ps-1">
                      <button type="button" className="btn btn-primary w-100" onClick={handleAssign}>Assign</button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">MOBILE</p>
                      <p className="text-dark fw-bold">{complaint.customer?.mobile}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">NAME</p>
                      <p className="text-dark fw-bold">{complaint.name || "N/A"}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">CATEGORY</p>
                      <p className="text-dark text-capitalize fw-bold">{complaint.category}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">STATUS</p>
                      {!edit && <p className="text-dark fw-bold" onDoubleClick={() => setEdit(true)}>{getComplaintStatus[complaint.status]}</p>}
                      {edit && <select ref={statusRef} className="form-select" onChange={handleStatusChange}>
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="assigned">Assigned</option>
                        <option value="resolved">Resolved</option>
                      </select>}
                    </div>
                    {complaint.driver && <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">DRIVER</p>
                      <p className="text-dark fw-bold">{complaint.driver.name}</p>
                    </div>}
                    {complaint.status === "resolved" && <div className="col-md-6">
                      <p className="text-muted fw-bold mb-0">RESOLVED BY</p>
                      <p className="text-dark fw-bold">{complaint.user?.name}</p>
                    </div>}
                  </div>
                  <div className="mb-3">
                    <p className="text-muted fw-bold mb-0">MESSAGE</p>
                    <p className="text-dark fw-bold">{complaint.message}</p>
                  </div>
                  {complaint.status !== "resolved" && <div className="text-end">
                    <button type="button" className="btn btn-primary" onClick={handleResolve}>Mark as Resolved</button>
                  </div>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bold">Comments</h3>
              {complaintComments.map((complaintComment) => {
                return (
                  <div key={complaintComment.id.toString()} className="card mb-2">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center bg-white border-bottom-0">
                        {complaintComment.user && <p className="fw-bold">{complaintComment.user.name}</p>}
                        {complaintComment.driver && <p className="fw-bold">{complaintComment.driver.name}</p>}
                        <p className="fst-italic text-muted small">{moment(complaintComment.created_at).fromNow()}</p>
                      </div>
                      <p className="m-0">{complaintComment.message}</p>
                    </div>
                  </div>
                )
              })}
              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-2">
                  <textarea ref={messageRef} className="form-control" id="message" placeholder="Complaint Comment" rows="8" required></textarea>
                  <label htmlFor="message">Complaint Comment</label>
                </div>
                <div className="text-end mb-2">
                  <button type="submit" className="btn btn-primary">Post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default User
