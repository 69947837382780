import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createCustomer = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/customer`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateCustomer = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/customer`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteCustomer = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/customer/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCustomers = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/customers?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCustomer = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/customer?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCustomerByMobile = async (mobile) => {
  const { data: response } = await axios.get(`${apiUrl}/customer/mobile?mobile=${mobile}`)
  return response
}

export const searchCustomers = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/customers/search`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}