import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Provider as StoreProvider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import "./index.css"
import store, { persistor } from "./store"
import Home from "./pages/Home"
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import Cart from "./pages/dashboard/Cart"
import Checkout from "./pages/dashboard/Checkout"
import Process from "./pages/dashboard/Process"
import Dispatch from "./pages/dashboard/Dispatch"
import Customer from "./pages/dashboard/Customer"
import Customers from "./pages/dashboard/Customers"
import Campaign from "./pages/dashboard/Campaign"
import Campaigns from "./pages/dashboard/Campaigns"
import Category from "./pages/dashboard/Category"
import Categories from "./pages/dashboard/Categories"
import Product from "./pages/dashboard/Product"
import Products from "./pages/dashboard/Products"
import Deal from "./pages/dashboard/Deal"
import Deals from "./pages/dashboard/Deals"
import Driver from "./pages/dashboard/Driver"
import Drivers from "./pages/dashboard/Drivers"
import Order from "./pages/dashboard/Order"
import EditOrder from "./pages/dashboard/EditOrder"
import Orders from "./pages/dashboard/Orders"
import TrashOrders from "./pages/dashboard/TrashOrders"
import PendingOrders from "./pages/dashboard/PendingOrders"
import Request from "./pages/dashboard/Request"
import Requests from "./pages/dashboard/Requests"
import OrderRequest from "./pages/dashboard/OrderRequest"
import OrderRequests from "./pages/dashboard/OrderRequests"
import OrderHistory from "./pages/dashboard/OrderHistory"
import ReportSummary from "./pages/dashboard/ReportSummary"
import ReportCustomers from "./pages/dashboard/ReportCustomers"
import ReportOrders from "./pages/dashboard/ReportOrders"
import ReportProducts from "./pages/dashboard/ReportProducts"
import ReportDrivers from "./pages/dashboard/ReportDrivers"
import ReportIncomeStatement from "./pages/dashboard/ReportIncomeStatement"
import ReportValuedCustomers from "./pages/dashboard/ReportValuedCustomers"
import ReportExpenses from "./pages/dashboard/ReportExpenses"
import Insights from "./pages/dashboard/Insights"
import User from "./pages/dashboard/User"
import Users from "./pages/dashboard/Users"
import Complaint from "./pages/dashboard/Complaint"
import Complaints from "./pages/dashboard/Complaints"
import DispatchComplaint from "./pages/dashboard/DispatchComplaint"
import IncomeStatement from "./pages/dashboard/SettingIncomeStatement"
import Permissions from "./pages/dashboard/Permissions"
import HomeDeal from "./pages/Deal"
import HomeProduct from "./pages/Product"
import HomeComplaint from "./pages/Complaint"
import DashboardLayout from "./components/DashboardLayout"
import CashFlowOut from "./pages/dashboard/CashFlowOut"
import CashFlowOuts from "./pages/dashboard/CashFlowOuts"
import CashFlowIn from "./pages/dashboard/CashFlowIn"
import CashFlowIns from "./pages/dashboard/CashFlowIns"
import "./App.css"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "process",
        element: <Process />,
      },
      {
        path: "dispatch",
        element: <Dispatch />,
      },
      {
        path: "customer",
        element: <Customer />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "campaign",
        element: <Campaign />,
      },
      {
        path: "campaigns",
        element: <Campaigns />,
      },
      {
        path: "order_history",
        element: <OrderHistory />,
      },
      {
        path: "category",
        element: <Category />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "deal",
        element: <Deal />,
      },
      {
        path: "deals",
        element: <Deals />,
      },
      {
        path: "driver",
        element: <Driver />,
      },
      {
        path: "drivers",
        element: <Drivers />,
      },
      {
        path: "edit_order",
        element: <EditOrder />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "trash_orders",
        element: <TrashOrders />,
      },
      {
        path: "pending_orders",
        element: <PendingOrders />,
      },
      {
        path: "request",
        element: <Request />,
      },
      {
        path: "requests",
        element: <Requests />,
      },
      {
        path: "order_request",
        element: <OrderRequest />,
      },
      {
        path: "order_requests",
        element: <OrderRequests />,
      },
      {
        path: "report/summary",
        element: <ReportSummary />,
      },
      {
        path: "report/customer",
        element: <ReportCustomers />,
      },
      {
        path: "report/order",
        element: <ReportOrders />,
      },
      {
        path: "report/product",
        element: <ReportProducts />,
      },
      {
        path: "report/rider",
        element: <ReportDrivers />,
      },
      {
        path: "report/income statement",
        element: <ReportIncomeStatement />,
      },
      {
        path: "report/valued customers",
        element: <ReportValuedCustomers />,
      },
      {
        path: "report/expenses",
        element: <ReportExpenses />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "complaint",
        element: <Complaint />,
      },
      {
        path: "complaints",
        element: <Complaints />,
      },
      {
        path: "dispatch-complaint",
        element: <DispatchComplaint />,
      },
      {
        path: "permissions",
        element: <Permissions />,
      },
      {
        path: "settings/incomestatement",
        element: <IncomeStatement />,
      },
      {
        path: "insights",
        element: <Insights />,
      },
      {
        path: "cash-flow-out",
        element: <CashFlowOut />,
      },
      {
        path: "cash-flow-outs",
        element: <CashFlowOuts />,
      },
      {
        path: "cash-flow-in",
        element: <CashFlowIn />,
      },
      {
        path: "cash-flow-ins",
        element: <CashFlowIns />,
      },
    ]
  },
  {
    path: "/deal/:slug",
    element: <HomeDeal />
  },
  {
    path: "/product/:slug",
    element: <HomeProduct />
  },
  {
    path: "/complaint",
    element: <HomeComplaint />
  },
])

const App = () => {
  return (
    <StoreProvider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </StoreProvider>
  )
}

export default App
