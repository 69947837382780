import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as reportService from "../../utilities/services/report"
import Layout from "../../components/Layout"

const ReportExpenses = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [expenses, setExpenses] = useState([])
  const [filters, setFilters] = useState({})

  const getExpenses = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await reportService.getExpenses(token, query.toString())
      if (!response.status) throw new Error(response.message)

      console.log(response.expenses);

      setExpenses(response.expenses)
    } catch (error) {
      setExpenses([])
    }
  }

  const handleSearch = () => {
    const where = {}

    if (formRef.current.from.value && formRef.current.to.value) {
      where.from = moment(formRef.current.from.value).format("YYYY-MM-DD")
      where.to = moment(formRef.current.to.value).format("YYYY-MM-DD")
    }

    setFilters(where)
  }

  const handleReset = () => {
    setFilters({})
  }

  useEffect(() => {
    if (!user) navigate("/")

    getExpenses({})
  }, [])

  useEffect(() => {
    getExpenses(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card h-100">
                  <div className="card-header p-2">
                    <div className="row align-items-center">
                      <div className="col-md-4 fw-bold">Expenses</div>
                      <div className="col-md-8">
                        <div className="row align-items-center">
                          <div className="col-md-4 ps-1" />
                          <div className="col-md-1 px-1">
                            <label className="col-form-label">From</label>
                          </div>
                          <div className="col-md-2 px-1">
                            <input type="date" className="form-control form-control-sm bg-light" name="from" />
                          </div>
                          <div className="col-md-1 px-1">
                            <label className="col-form-label">To</label>
                          </div>
                          <div className="col-md-2 px-1">
                            <input type="date" className="form-control form-control-sm bg-light" name="to" />
                          </div>
                          <div className="col-md-1 px-1">
                            <button type="button" className="btn btn-sm btn-primary w-100" onClick={handleSearch}><i className="fas fa-search"></i></button>
                          </div>
                          <div className="col-md-1 ps-1">
                            <button type="reset" className="btn btn-sm btn-danger w-100" onClick={handleReset}><i className="fas fa-times"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <table className="table table-sm table-hover shadow-none text-dark mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Order Date</th>
                          <th scope="col" style={{ width: "8%" }} className="align-middle">Total Quantity</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Total Product Cost</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Total Sales Value</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Number Of Orders</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Total Delivery Charges</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Labor</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Delivery Cost Percentage</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Labor Cost Percentage</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Total Production Cost Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenses.map((expense, index) => (
                          <tr key={index}>
                            <td className="align-middle">{expense.order_date}</td>
                            <td className="align-middle">{expense.total_quantity}</td>
                            <td className="align-middle">{expense.total_product_cost}</td>
                            <td className="align-middle">{expense.total_sales_value}</td>
                            <td className="align-middle">{expense.number_of_orders}</td>
                            <td className="align-middle">{expense.total_delivery_charges}</td>
                            <td className="align-middle">{expense.labor}</td>
                            <td className="align-middle">{expense.delivery_cost_percentage?.toFixed(2)}%</td>
                            <td className="align-middle">{expense.labor_cost_percentage?.toFixed(2)}%</td>
                            <td className="align-middle">{expense.total_production_cost_percentage?.toFixed(2)}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </Layout >
  )
}

export default ReportExpenses
