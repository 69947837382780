import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createComplaint = async (payload) => {
  const { data: response } = await axios.post(`${apiUrl}/complaint`, payload)
  return response
}

export const updateComplaint = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/complaint`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteComplaint = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/complaint/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getComplaints = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/complaints?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getComplaint = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/complaint?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const restoreComplaint = async (token, id) => {
  const { data: response } = await axios.patch(`${apiUrl}/complaint/${id}/restore`, {}, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const resolveComplaint = async (token, payload) => {
  const { data: response } = await axios.patch(`${apiUrl}/complaint/resolve`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}