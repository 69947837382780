import { NavLink } from "react-router-dom"
import "../style.css"

const GuestLayout = (props) => {
  const jumpTo = (id) => {
    const section = document.getElementById(id)
    if (section) section.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      {
        props.header && <nav className="navbar navbar-expand-lg navbar-dark py-1">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">Phonics</NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#hero" onClick={() => jumpTo("hero")}>Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#deals" onClick={() => jumpTo("deals")}>Deals</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#products" onClick={() => jumpTo("products")}>Products</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#about" onClick={() => jumpTo("about")}>About</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#contact" onClick={() => jumpTo("contact")}>Contact</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/complaint">Complaint</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">Login</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/#app" onClick={() => jumpTo("app")}>Rider App</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      }
      {props.children}
      {
        props.footer && <footer className="py-5">
          <p className="lead m-0">Phonics &copy; {new Date().getFullYear()}, Powered by <a href="https://interacksolutions.com?source=phonicspk.com" className="text-decoration-none" target="_blank">Interack Solutions LLC</a></p>
        </footer>
      }
    </>
  )
}

export default GuestLayout
