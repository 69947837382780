import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import * as complaintApis from "../utilities/apis/complaint"
import * as customerApis from "../utilities/apis/customer"
import GuestLayout from "../components/GuestLayout"

const Complaint = () => {
  const navigate = useNavigate()
  const categoryRef = useRef(null)
  const mobileRef = useRef(null)
  const nameRef = useRef(null)
  const messageRef = useRef(null)
  const [mobile, setMobile] = useState(null)
  const [customer, setCustomer] = useState(false)

  const handleChange = (e) => {
    if (e.target.value) setMobile(e.target.value)
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const payload = {
        category: categoryRef.current.value,
        mobile: mobileRef.current.value,
        name: nameRef.current.value || null,
        message: messageRef.current.value
      }
      const response = await complaintApis.createComplaint(payload)
      if (!response.status) throw new Error(response.message)

      alert("Complaint submitted successfully")
      navigate("/")
    } catch (error) {
      alert("Error creating order: " + error.message)
    }
  }

  const getCustomer = async () => {
    try {
      const response = await customerApis.getCustomerByMobile(mobile)
      if (!response.status) throw new Error(response.message)

      if (response.customer) setCustomer(true)
    } catch (error) {
      setCustomer(false)
    }
  }

  useEffect(() => {
    if (mobile) getCustomer()
  }, [mobile])

  return (
    <GuestLayout
      header={true}
      footer={true}
    >
      <div id="complaint" className="container py-4">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <h3 className="fw-bold m-0">Report a Complaint</h3>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-2">
                    <div class="form-floating">
                      <select ref={categoryRef} class="form-select" id="category">
                        <option value="">Select Category</option>
                        <option value="payment issue">Payment Issue</option>
                        <option value="packing issue">Packing Issue</option>
                        <option value="missing item">Missing Item</option>
                        <option value="product quality">Product Quality</option>
                        <option value="delivery issue">Delivery Issue</option>
                        <option value="miscellaneous">Miscellaneous</option>
                      </select>
                      <label for="category">Category</label>
                    </div>
                  </div>
                  <div className="input-group mb-2">
                    <div className="form-floating">
                      <input ref={mobileRef} type="text" className="form-control border-end-0" id="mobile" placeholder="Mobile" required onChange={handleChange} />
                      <label htmlFor="mobile">Mobile No</label>
                    </div>
                    <span class="input-group-text bg-white border-start-0" id="basic-addon1"><i className={`fas fa-${customer ? 'check' : 'exclamation'}-circle text-${customer ? 'success' : 'danger'}`} title={customer ? '' : 'If you are the phonics customer then type your mobile number to submit a complaint'}></i></span>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={nameRef} type="text" className="form-control border-end-0" id="name" placeholder="Name" />
                    <label htmlFor="name">Name</label>
                  </div>
                  <div className="form-floating mb-2">
                    <textarea ref={messageRef} className="form-control" id="message" placeholder="Please tell us about your complaint" rows="8" required></textarea>
                    <label htmlFor="message">Please tell us about your complaint</label>
                  </div>
                  <hr />
                  <button type="submit" className="btn btn-primary w-100" disabled={!customer}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default Complaint
