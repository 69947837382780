import moment from "moment"

export const url = process.env.REACT_APP_BACKEND_URL

export const apiUrl = `${url}/api`
export const serviceUrl = `${url}/service`
export const getProcessedStatus = {
  false: <span className="badge bg-light border text-danger">Unprocessed</span>,
  true: <span className="badge bg-light border text-success">Processed</span>,
}
export const getOrderStatus = {
  pending: <span className="badge bg-pink">Pending</span>,
  punched: <span className="badge bg-primary">Punched</span>,
  requested: <span className="badge bg-light border">Requested</span>,
  assigned: <span className="badge bg-dark">Assigned</span>,
  packed: <span className="badge bg-info">Packed</span>,
  onway: <span className="badge bg-warning">Onway</span>,
  delivered: <span className="badge bg-success">Delivered</span>,
}
export const getPaymentStatus = {
  unpaid: <span className="badge bg-danger">Unpaid</span>,
  requested: <span className="badge bg-warning">Requested</span>,
  paid: <span className="badge bg-success">Paid</span>,
}
export const getRequestStatus = {
  requested: <span className="badge bg-warning">Requested</span>,
  completed: <span className="badge bg-success">Completed</span>,
  rejected: <span className="badge bg-danger">Rejected</span>,
}
export const getOrderRequestType = {
  cancel: <span className="badge bg-danger">Cancel Request</span>,
  reroute: <span className="badge bg-warning">Reroute Request</span>,
}
export const getComplaintStatus = {
  pending: <span className="badge bg-warning">Pending</span>,
  assigned: <span className="badge bg-info">Assigned</span>,
  resolved: <span className="badge bg-success">Resolved</span>,
}

export const days = [
  { value: "", name: "All", slug: "" },
  { value: "01", name: "Monday", slug: "Mon" },
  { value: "02", name: "Tuesday", slug: "Tue" },
  { value: "03", name: "Wednesday", slug: "Wed" },
  { value: "04", name: "Thursday", slug: "Thu" },
  { value: "05", name: "Friday", slug: "Fri" },
  { value: "06", name: "Saturday", slug: "Sat" },
  { value: "07", name: "Sunday", slug: "Sun" },
]
export const months = [
  { value: "", name: "All", slug: "" },
  { value: "01", name: "January", slug: "Jan" },
  { value: "02", name: "February", slug: "Feb" },
  { value: "03", name: "March", slug: "Mar" },
  { value: "04", name: "April", slug: "Apr" },
  { value: "05", name: "May", slug: "May" },
  { value: "06", name: "June", slug: "Jun" },
  { value: "07", name: "July", slug: "Jul" },
  { value: "08", name: "August", slug: "Aug" },
  { value: "09", name: "September", slug: "Sep" },
  { value: "10", name: "October", slug: "Oct" },
  { value: "11", name: "November", slug: "Nov" },
  { value: "12", name: "December", slug: "Dec" },
]
export const years = [
  { value: "", name: "All", slug: "" },
  { value: "01", name: "2024", slug: "2024" },
  { value: "02", name: "2023", slug: "2023" },
  { value: "03", name: "2022", slug: "2022" },
  { value: "04", name: "2021", slug: "2021" },
  { value: "05", name: "2020", slug: "2020" },
]
export const isDeal = (type) => type === "deal"
export const isProduct = (type) => type === "product"