import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as reportService from "../../utilities/services/report"
import Layout from "../../components/Layout"

const ReportValuedCustomers = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [customers, setCustomers] = useState([])
  const [filters, setFilters] = useState({
    offset: 0,
    limit: 10,
  })

  const getValuedCustomers = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await reportService.getValuedCustomers(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setCustomers(response.customers)
    } catch (error) {
      setCustomers([])
    }
  }

  const handleSearch = () => {
    const where = {}

    if (formRef.current.last_order.value) where.last_order = moment(formRef.current.last_order.value).format("YYYY-MM-DD")
    if (formRef.current.limit.value) where.limit = formRef.current.limit.value

    setFilters(where)
  }

  const handleReset = () => {
    setFilters({})
  }

  useEffect(() => {
    if (!user) navigate("/")

    getValuedCustomers({})
  }, [])

  useEffect(() => {
    getValuedCustomers(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card h-100">
                  <div className="card-header p-2">
                    <div className="row align-items-center">
                      <div className="col-md-4 fw-bold">Valued Customers</div>
                      <div className="col-md-8">
                        <div className="row align-items-center">
                          <div className="col-md-5 ps-1" />
                          <div className="col-md-1 px-1">
                            <label className="col-form-label">Last Order</label>
                          </div>
                          <div className="col-md-2 px-1">
                            <input type="date" className="form-control form-control-sm bg-light" name="last_order" />
                          </div>
                          <div className="col-md-1 px-1">
                            <label className="col-form-label">Limit</label>
                          </div>
                          <div className="col-md-1 px-1">
                            <input type="number" className="form-control form-control-sm bg-light" name="limit" />
                          </div>
                          <div className="col-md-1 px-1">
                            <button type="button" className="btn btn-sm btn-primary w-100" onClick={handleSearch}><i className="fas fa-search"></i></button>
                          </div>
                          <div className="col-md-1 ps-1">
                            <button type="reset" className="btn btn-sm btn-danger w-100" onClick={handleReset}><i className="fas fa-times"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <table className="table table-sm table-hover shadow-none text-dark mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Mobile</th>
                          <th scope="col" style={{ width: "8%" }} className="align-middle">Address</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Zone</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">First</small><br />Order</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Last</small><br />Order</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Length as</small><br />Customer<br /><small className="text-muted">in Months</small></th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Order Avg</small><br />Frequency<br /><small className="text-muted">in Days</small></th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Last</small><br />Not Ordered<br /><small className="text-muted">in 3 Months</small></th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Chaseable</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle">Delay<br /><small className="text-muted">in Days</small></th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Total</small><br />Orders</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Total</small><br />Value</th>
                          <th scope="col" style={{ width: "8%" }} className="align-middle"><small className="text-muted">Top</small><br />Product</th>
                          <th scope="col" style={{ width: "7%" }} className="align-middle"><small className="text-muted">Top</small><br />Product<br /><small className="text-muted">Qty</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((order, index) => (
                          <tr key={index}>
                            <td className="align-middle">{order.mobile}</td>
                            <td className="align-middle">{order.address}</td>
                            <td className="align-middle">{order.zone_name}</td>
                            <td className="align-middle">{moment(order.first_order_datetime).format('DD MMM YYYY')}</td>
                            <td className="align-middle">{moment(order.last_order_datetime).format('DD MMM YYYY')}</td>
                            <td className="align-middle">{order.length_as_customer_months}</td>
                            <td className="align-middle">{order.avg_order_frequency_days.toFixed(2)}</td>
                            <td className="align-middle">{order.not_ordered_last_3_months ? "Yes" : "No"}</td>
                            <td className="align-middle">{order.should_be_chased ? "Yes" : "No"}</td>
                            <td className="align-middle">{order.delayed_by_days}</td>
                            <td className="align-middle">{order.total_orders}</td>
                            <td className="align-middle">{order.total_value}</td>
                            <td className="align-middle">{order.top_product}</td>
                            <td className="align-middle">{order.top_product_quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </Layout >
  )
}

export default ReportValuedCustomers
