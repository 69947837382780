import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as driverApis from "../../utilities/apis/driver"
import * as orderApis from "../../utilities/apis/order"
import * as complaintApis from "../../utilities/apis/complaint"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const Dispatch = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const driverRef = useRef(null)
  const [drivers, setDrivers] = useState([])
  const [leftItems, setLeftItems] = useState([])
  const [rightItems, setRightItems] = useState([])
  const [commission, setCommission] = useState(0)

  const pushRight = (order) => {
    setRightItems(previous => {
      previous.push(order)
      return previous
    })
    setLeftItems(previous => previous.filter(x => x.id !== order.id))
  }

  const popRight = (order) => {
    setLeftItems(previous => {
      previous.push(order)
      return previous
    })
    setRightItems(previous => previous.filter(x => x.id !== order.id))
  }

  const getDrivers = async () => {
    try {
      const response = await driverApis.getDrivers(token, {})
      if (!response.status) throw new Error(response.message)

      const activeDriver = response.drivers.filter(driver => driver.active === true)
      setDrivers(activeDriver)
    } catch (error) {
      alert(error.message)
    }
  }

  const getComplaints = async () => {
    try {
      const query = new URLSearchParams({
        status: "pending"
      })
      const response = await complaintApis.getComplaints(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setLeftItems(response.complaints)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = (e) => {
    if (e.target.selectedIndex > 0)
      setCommission(drivers[e.target.selectedIndex - 1].commission)
    else
      setCommission(0)
  }

  const handleSubmit = async () => {
    try {
      if (!driverRef.current.value) return alert("Please select a driver")
      if (rightItems.length === 0) return alert("Please move atleast 1 complaint to the right")

      const payload = rightItems.map((item) => {
        return {
          complaint_id: item.id,
          driver_id: driverRef.current.value
        }
      })

      const response = await dispatcherServices.complaintAssigned(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Complaint assigned")
      driverRef.current.value = ""
      setRightItems(() => [])
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDrivers()
    getComplaints()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="flex-column">
        <div className="row align-items-center p-2">
          <div className="col-4">
            <h3 className="fw-bold m-0">Complaints <small>({leftItems.length})</small></h3>
          </div>
          <div className="col-4 text-center">
            <button className="btn btn-primary w-50" onClick={handleSubmit}>Assign to Rider</button>
          </div>
          <div className="col-4">
            <div className="row align-items-center">
              <div className="col-6 text-end">
                <h5 className="fw-bold m-0">Complaints <small>({rightItems.length})</small></h5>
              </div>
              <div className="col-6">
                <select ref={driverRef} onChange={handleChange} className="form-select w-100">
                  <option key="0" value="">Select Rider</option>
                  {drivers.map((driver) => (
                    <option key={driver.id.toString()} value={driver.id}>{driver.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow-1 d-flex overflow-y-auto border-top">
          <section className="flex-grow-1 border-end">
            <div className="accordion accordion-flush">
              {leftItems.map((complaint, index) => {
                return (
                  <div key={`left-${complaint.id}`} className="d-flex">
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-left-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-left-${index}`} aria-expanded="true" aria-controls={`collapse-left-${index}`}>
                          <span className="fw-bold me-1">COMPLAINT #:</span><span className="me-3">C{complaint.id}</span> |
                          <span className="fw-bold ms-3 me-1">ZONE:</span><span className="me-3">{complaint.customer.zone.name}</span> |
                          <span className="ms-3 me-1">{moment(complaint.created_at).format("DD-MM-YYYY HH:mm")}</span>
                        </button>
                      </h2>
                      <div id={`collapse-left-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-left-${index}`} data-bs-parent="#accordion-left">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-4">
                              <h5>Customer</h5>
                              <p className="m-0">{complaint.customer.mobile}</p>
                              <p className="m-0">{complaint.message}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary rounded-0 shadow-none" onClick={() => pushRight(complaint)}><i className="fas fa-chevron-right"></i></button>
                  </div>
                )
              })}
            </div>
          </section>
          <aside className="flex-grow-1 border-start">
            <div className="accordion accordion-flush" id="accordion-right">
              {rightItems.map((complaint, index) => {
                return (
                  <div key={`right-${complaint.id}`} className="d-flex">
                    <button className="btn btn-primary rounded-0 shadow-none" onClick={() => popRight(complaint)}><i className="fas fa-chevron-left"></i></button>
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-right-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-right-${index}`} aria-expanded="true" aria-controls={`collapse-right-${index}`}>
                          <span className="fw-bold me-1">COMPLAINT #:</span><span className="me-3">C{complaint.id}</span> |
                          <span className="fw-bold ms-3 me-1">ZONE:</span><span className="me-3">{complaint.customer.zone.name}</span> |
                          <span className="ms-3 me-1">{moment(complaint.created_at).format("DD-MM-YYYY HH:mm")}</span>
                        </button>
                      </h2>
                      <div id={`collapse-right-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-right-${index}`} data-bs-parent="#accordion-right">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-4">
                              <h5>Customer</h5>
                              <p className="m-0">{complaint.customer.mobile}</p>
                              <p className="m-0">{complaint.message}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </aside>
        </div>
      </main>
    </Layout>
  )
}

export default Dispatch
